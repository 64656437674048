class OLMCEvent {
    constructor(id, 
        eventType, 
        eventData, 
        state, 
        type, 
        creationDate, 
        processId,
        olmcMessageId) {
            this.id = id;
            this.eventType = eventType;
            this.eventData = eventData;
            this.state = state;
            this.type = type;
            this.creationDate = creationDate;
            this.processId = processId;
            this.olmcMessageId = olmcMessageId;
    }
}

export default OLMCEvent;