class GasContract {
    constructor(id, 
        creationDate,
        cui, 
        contractHolderFiscalNumber,
        gasContractType, 
        processId,
        processState) {
            this.id = id;
            this.creationDate = creationDate;
            this.cui = cui;
            this.contractHolderFiscalNumber = contractHolderFiscalNumber;
            this.gasContractType = gasContractType;
            this.processId = processId;
            this.processState = processState;
    }
}

export default GasContract;