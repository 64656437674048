class OLMCMessage {
    constructor(id, 
        creationDate, 
        state, 
        type, 
        processCode, 
        step, 
        olmcProcessId, 
        cui, 
        olmcCurrentMessageId, 
        processId,
        processType) {
            this.id = id;
            this.creationDate = creationDate;
            this.state = state;
            this.type = type;
            this.processCode = processCode;
            this.step = step;
            this.olmcProcessId = olmcProcessId;
            this.cui = cui;
            this.olmcCurrentMessageId = olmcCurrentMessageId;
            this.processId = processId;
            this.processType = processType;
    }
}

export default OLMCMessage;