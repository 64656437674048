class PluginConfigurations {
    constructor(pluginName,
        key,
        value) {
            this.pluginName = pluginName;
            this.key = key;
            this.value = value;
    }
}

export default PluginConfigurations;