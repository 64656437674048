import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import JWTProvider from '../../apiservices/JWTProvider'
import React from 'react';
import integrationApi from 'apiservices/integrationApi';
import {
  MdDashboard,
  MdMessage,
  MdDescription,
  MdForum,
  MdPersonPin,
  MdSettingsApplications,
  MdClearAll,
  MdKeyboardArrowDown,
  MdPower,
  MdFileUpload
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const pageContents = [
  { to: '/plugin-configurations', name: 'plugins', exact: false, Icon: MdPower, role: 'Admin' },
  { to: '/step-configurations', name: 'steps', exact: false, Icon: MdClearAll, role: 'Admin' },
  { to: '/user', name: 'utilizadores', exact: false, Icon: MdPersonPin, role: 'Admin' },
];

const navItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard, role: 'ServiceManager' },
  { to: '/processes', name: 'processos', exact: false, Icon: MdDescription, role: 'ServiceManager' },
  { to: '/olmc-messages', name: 'mensagens olmc', exact: false, Icon: MdMessage, role: 'ServiceManager' },
  //{ to: '/gas-contracts', name: 'contractos de gás', exact: false, Icon: MdAssignment, role: 'ServiceManager' },
  //{ to: '/olmc-processes', name: 'processos olmc', exact: false, Icon: MdDescription, role: 'ServiceManager' },
  { to: '/integration-results', name: 'integrações', exact: false, Icon: MdForum, role: 'ServiceManager' },
  //{ to: '/plugin-configurations', name: 'plugins', exact: false, Icon: MdSettingsApplications, role: 'Admin' },
  //{ to: '/step-configurations', name: 'steps', exact: false, Icon: MdSettingsApplications, role: 'Admin' },
  //{ to: '/user', name: 'utilizadores', exact: false, Icon: MdPersonPin, role: 'Admin' },
  { to: '/processes-import', name: 'importar', exact: false, Icon: MdFileUpload, role: 'ServiceManager' }
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: false,
    logo: ""
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  componentDidMount() {
    this.integrationApi = integrationApi.GetIntegrationAPI();

    var config = this.integrationApi.GetReactConfig();

    this.setState({ logo: config.LOGO });
  }

  render() {
    let userRoles = JWTProvider.GetRoles();
    let filterNavItems = [];
    var logo = this.state.logo;

    navItems.forEach(item => {
      if(!item.role || (userRoles.indexOf(item.role) !== -1)){
          filterNavItems.push(item);
        }
    });

    if((userRoles.indexOf("Admin") !== -1)){
      return (
        <aside className={bem.b()} data-image={sidebarBgImage}>
          <div className={bem.e('background')} style={sidebarBackground} />
          <div className={bem.e('content')}>
            <Navbar>
              <SourceLink className="navbar-brand d-flex">
                <span className="text-white">
                  <img src={logo} width="190" height="49.6" alt="" style={{marginTop: 4, borderRadius: "20"}} />
                </span>
              </SourceLink>
            </Navbar>
            <Nav vertical style={{margin: '0'}}>
              {filterNavItems.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} style={{width: '25px'}} />
                    <span className="" style={{fontSize: '14px'}}>{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
  
              <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Pages')}
              >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSettingsApplications className={bem.e('nav-item-icon')} />
                  <span className="">Configurações</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenPages}>
                {pageContents.map(({ to, name, exact, Icon, role }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="" style={{fontSize: '14px'}}>{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
  
            </Nav>
          </div>
        </aside>
      );
    } else {
      return (
        <aside className={bem.b()} data-image={sidebarBgImage}>
          <div className={bem.e('background')} style={sidebarBackground} />
          <div className={bem.e('content')}>
            <Navbar>
              <SourceLink className="navbar-brand d-flex">
                <span className="text-white">
                  <img src={logo} width="190" height="49.6" alt="" style={{marginTop: 4, borderRadius: "20"}} />  
                </span>
              </SourceLink>
            </Navbar>
            <Nav vertical style={{margin: '0'}}>
              {filterNavItems.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} style={{width: '25px'}} />
                    <span className="" style={{fontSize: '14px'}}>{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
  
            </Nav>
          </div>
        </aside>
      );
    }
  }
}

export default Sidebar;
