class User {
    constructor(email, 
        isActive,
        roles,) {
            this.email = email;
            this.isActive = isActive;
            this.roles = roles;
    }
}

export default User;