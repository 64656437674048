import { UserCard } from 'components/Card';
import React from 'react';
import integrationApi from 'apiservices/integrationApi';
import {
  MdClearAll,
  MdExitToApp,
  MdSettingsApplications,
  MdFace
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import JWTProvider from 'apiservices/JWTProvider';
import { Link } from 'react-router-dom';

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    environment: ""
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  handleLogout() {
    JWTProvider.RemoveToken();
    window.location.href = "/";
  }

  componentDidMount() {
    this.integrationApi = integrationApi.GetIntegrationAPI();

    var config = this.integrationApi.GetReactConfig();

    this.setState({ environment: config.ENV });
  }

  render() {

    const username = JWTProvider.GetUsername();

    var env = this.state.environment;

    return (
      <>
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex" style={{ alignItems: "center" }}>
            <NavLink id="Popover1" className="position-relative">
            {username}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink id="Popover2">
              <MdFace style={{ fontSize: "35px" }} onClick={this.toggleUserCardPopover} className="can-click" />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title="Jane"
                  subtitle="jane@jane.com"
                  text="Last updated 3 mins ago"
                  className="border-light"
                >
                  <ListGroup flush>
                    <Link to={"/edit-password/"} style={{textDecoration: "none"}}>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdSettingsApplications style={{ fontSize: "18px" }} /> Alterar Password
                      </ListGroupItem>
                    </Link>
                    <ListGroupItem tag="button" action className="border-light" onClick={this.handleLogout}>
                      <MdExitToApp style={{ fontSize: "18px" }} /> Logout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>

      {
        env === "PRODUÇÃO" ?
        <span></span> :
        <Navbar light expand style={{backgroundColor: "red", height: "75px", marginBottom: "10px"}}>
          <span style={{color: "white", fontSize: "24px"}}>AMBIENTE: {env}</span>
        </Navbar>
      }
      </>
    );
  }
}

export default Header;
