class JWTProvider {

    static STORAGE_KEY = "JWTTOKEN";

    static HasToken(){
        let token = JSON.parse(localStorage.getItem(JWTProvider.STORAGE_KEY));
        
        if(!token)
            return false;

        if(new Date(token.expirantionDate) < new Date((new Date()).toUTCString())){
            JWTProvider.RemoveToken();
            return false;
        }

        return true;
    }

    static GetDecodedJWTToken() {
        if(!JWTProvider.HasToken()){
            return null;
        }

        return JSON.parse(window.atob(JWTProvider.GetToken().split('.')[1]));
    }

    static GetRoles(){
        let decodedJwtData = this.GetDecodedJWTToken();

        if(!decodedJwtData) {
            return [];
        }

        var userRoles = decodedJwtData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

        if(!userRoles){
            return [];
        } else {
            return userRoles;
        }
    }
    
    static GetUsername() {
        let decodedJwtData = this.GetDecodedJWTToken();

        if(!decodedJwtData) {
            return null;
        }

        var username = decodedJwtData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

        if(!username){
            return "";
        } else {
            return username;
        }
    }

    static SetToken(token, expirantionDate){
        if(Date.parse(expirantionDate) === isNaN)
            throw new Error("Invalid date from token");

        localStorage.setItem(JWTProvider.STORAGE_KEY, JSON.stringify({ token: token, expirantionDate: expirantionDate }));
    }

    static GetToken(){
        if(!JWTProvider.HasToken())
            return;
        
        let token = JSON.parse(localStorage.getItem(JWTProvider.STORAGE_KEY));

        return token.token;
    }

    static RemoveToken(){
        localStorage.removeItem(JWTProvider.STORAGE_KEY);
    }
}

export default JWTProvider;