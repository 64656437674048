class OLMCProcess {
    constructor(olmcProcessId, 
        processCode, 
        cui, 
        totalMessages, 
        lastUpdateDate, 
        creationDate) {
            this.olmcProcessId = olmcProcessId;
            this.processCode = processCode;
            this.cui = cui;
            this.totalMessages = totalMessages;
            this.lastUpdateDate = lastUpdateDate;
            this.creationDate = creationDate;
    }
}

export default OLMCProcess;