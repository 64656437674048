class StepConfigurations {
    constructor(process,
        step,
        active,
        creationDate,
        type) {
            this.process = process;
            this.step = step;
            this.active = active;
            this.creationDate = creationDate;
            this.type = type;
    }
}

export default StepConfigurations;