class IntegrationResult {
    constructor(id, 
        pluginName, 
        processKey, 
        isSuccess, 
        description, 
        creationDate,
        olmcMessageId, 
        processId,
        processType,
        cui,
        olmcMessageOLMCProcessId, 
        olmcMessageOLMCProcess, 
        olmcMessageOLMCStep,
        reprocessDate,
        state) {
            this.id = id;
            this.pluginName = pluginName;
            this.processKey = processKey;
            this.isSuccess = isSuccess;
            this.description = description;
            this.creationDate = creationDate;
            this.olmcMessageId = olmcMessageId;
            this.processId = processId;
            this.cui = cui;
            this.olmcMessageOLMCProcessId = olmcMessageOLMCProcessId;
            this.olmcMessageOLMCProcess = olmcMessageOLMCProcess;
            this.olmcMessageOLMCStep = olmcMessageOLMCStep;
            this.reprocessDate = reprocessDate;
            this.processType = processType;
            this.state = state;
    }
}

export default IntegrationResult;