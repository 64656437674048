import OLMCMessage from '../models/olmcMessage';
import GasContract from '../models/gasContract';
import OLMCProcess from '../models/olmcProcess';
import Process from '../models/process';
import IntegrationResult from '../models/integrationResult';
import User from '../models/user';
import OLMCEvent from '../models/olmcEvent';
import PluginConfigurations from '../models/pluginConfigurations';
import StepConfigurations from '../models/stepConfigurations';
import JWTProvider from './JWTProvider';
import ProcessesImport from '../models/processesImport';

class integrationApi {
    constructor(data) {
        this.OLMCMessagesAPIEndpoint = data.REST_API_OLMCMessages;
        this.GasContractsAPIEndpoint = data.REST_API_GasContracts;
        this.OLMCProcessesAPIEndpoint = data.REST_API_OLMCProcesses;
        this.IntegrationResultsAPIEndpoint = data.REST_API_IntegrationResults;
        this.ConfigurationAPIEndpoint = data.REST_API_Configuration;
        this.UsersAPIEndpoint = data.REST_API_IdentityUsers;
        this.IdentityAPIEndpoint = data.REST_API_IdentityLogin;
        this.ProcessAPIEndpoint = data.REST_API_Process;
        this.OLMCEventsAPIEndpoint = data.REST_API_OLMCEvents;
        this.EnvironmentPing = data.REST_ENV_PING;
        this.ReactConfig = data.REACT_CONFIG;
        this.ProcessesImportEndpoint = data.REST_API_ProcessesImport;
    }

    static BuildIntegrationAPI(data){
        this.integrationAPI = new integrationApi(data);
    }

    static GetIntegrationAPI(){
        return this.integrationAPI;
    }

    GetToken(username, password){
        return fetch(this.IdentityAPIEndpoint + "/login", { method: 'POST',  
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },  
          body: JSON.stringify({ Email : username, Password: password }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            return { result: data }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetOLMCRawMessage(olmcMessageId) {
        let APIResult = this.OLMCMessagesAPIEndpoint + "/" + olmcMessageId  + "/rawmessage";
        
        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.text();
        })
        .then(data => { 
            return { olmcrawmessage: data }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetPluginPayload(pluginResultId) {
        let APIResult = this.IntegrationResultsAPIEndpoint + "/" + pluginResultId  + "/payload";
        
        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.text();
        })
        .then(data => { 
            return { pluginPayload: data }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetPluginHttpResponse(pluginResultId) {
        let APIResult = this.IntegrationResultsAPIEndpoint + "/" + pluginResultId  + "/response";
        
        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.text();
        })
        .then(data => { 
            return { pluginHttpResponse: data }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetRequestPayload(processId) {
        const APIResult = this.ProcessAPIEndpoint + "/" + processId + "/payload";

        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.text();
        })
        .then(data => {
            return { requestpayload: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    CloseProcess(processId) {
        const APIResult = this.ProcessAPIEndpoint + "/" + processId + "/close";

        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    RecallProcess(processId) {
        const APIResult = this.ProcessAPIEndpoint + "/" + processId + "/recall";

        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetOLMCMessages(currentPage, numberOfMessages, processId, olmcProcessId, cui, state, type) {
        let APIResult = this.OLMCMessagesAPIEndpoint + '?page=' + currentPage + '&pagesize=' + numberOfMessages;
        
        let token = JWTProvider.GetToken();

        if(processId) {
            APIResult += '&processId=' + processId;
        }

        if(olmcProcessId) {
            APIResult += '&olmcProcessId=' + olmcProcessId;
        }

        if(cui) {
            APIResult += '&cui=' + cui;
        }

        if(state) {
            APIResult += '&state=' + state;
        }

        if(type) {
            APIResult += '&type=' + type;
        }

        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let olmcMessages = [];

            data.results.map(message => olmcMessages.push(
                new OLMCMessage(message.id,
                    message.creationDate,
                    message.state,
                    message.type,
                    message.processCode,
                    message.step,
                    message.olmcProcessId,
                    message.cui,
                    message.olmcCurrentMessageId,
                    message.processId,
                    message.processType)
            ));
            
            return { results: olmcMessages, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetGasContracts(currentPage, numberOfMessages, cui, nif, state, type, processId) {
        let APIResult = this.GasContractsAPIEndpoint + '?page=' + currentPage + '&pagesize=' + numberOfMessages;

        let token = JWTProvider.GetToken();

        if(cui) {
            APIResult += '&cui=' + cui;
        }

        if(nif) {
            APIResult += '&nif=' + nif;
        }

        if(state) {
            APIResult += '&state=' + state;
        }

        if(type) {
            APIResult += '&type=' + type;
        }

        if(processId) {
            APIResult += '&processId=' + processId;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let gasContracts = [];

            data.results.map(contract => gasContracts.push(
                new GasContract(contract.id, 
                    contract.creationDate,
                    contract.cui, 
                    contract.contractHolderFiscalNumber,
                    contract.gasContractType,
                    contract.processId,
                    contract.processState)
            ));

            return { results: gasContracts, total: data.total };
        })
        .catch(function(error) {
            console.log(error);
        });;
    }

    GetOLMCProcesses(currentPage, numberOfMessages, olmcProcessId, cui, processCode) {
        let APIResult = this.OLMCProcessesAPIEndpoint + '?page=' + currentPage + '&pagesize=' + numberOfMessages;

        let token = JWTProvider.GetToken();

        if(olmcProcessId) {
            APIResult += '&olmcProcessId=' + olmcProcessId;
        }

        if(cui) {
            APIResult += '&cui=' + cui;
        }

        if(processCode) {
            APIResult += '&processCode=' + processCode;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let olmcProcesses = [];

            data.results.map(process => olmcProcesses.push(
                new OLMCProcess(process.olmcProcessId, 
                    process.processCode,
                    process.cui, 
                    process.totalMessages,
                    process.lastUpdateDate,
                    process.creationDate)
            ));
            
            return { results: olmcProcesses, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetIntegrationResults(currentPage, numberOfMessages, olmcProcessId, cui, processCode, processKey, processResult, olmcMessageId, state) {
        let APIResult = this.IntegrationResultsAPIEndpoint + '?page=' + currentPage + '&pagesize=' + numberOfMessages;

        let token = JWTProvider.GetToken();

        if(olmcProcessId) {
            APIResult += '&olmcProcessId=' + olmcProcessId;
        }

        if(cui) {
            APIResult += '&cui=' + cui;
        }

        if(processCode) {
            APIResult += '&processCode=' + processCode;
        }

        if(processKey) {
            APIResult += '&processKey=' + processKey;
        }

        if(processResult) {
            APIResult += '&processResult=' + processResult;
        }

        if(olmcMessageId) {
            APIResult += '&olmcMessageId=' + olmcMessageId;
        }

        if(state) {
            APIResult += '&state=' + state;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let integrationResults = [];

            data.results.map(result => integrationResults.push(
                new IntegrationResult(result.id,
                    result.pluginName,
                    result.processKey,
                    result.isSuccess,
                    result.description,
                    result.creationDate,
                    result.olmcMessageId,
                    result.processId,
                    result.processType,
                    result.cui,
                    result.olmcMessageOLMCProcessId,
                    result.olmcMessageOLMCProcess,
                    result.olmcMessageOLMCStep,
                    result.reprocessDate,
                    result.state)
            ));

            return { results: integrationResults, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetProcesses(currentPage, numberOfProcesses, state, id, olmcProcessCode, type, olmcProcessId) {
        let APIResult = this.ProcessAPIEndpoint + '?page=' + currentPage + '&pagesize=' + numberOfProcesses;

        let token = JWTProvider.GetToken();

        if(state) {
            APIResult += "&state=" + state;
        }

        if(id) {
            APIResult += "&id=" + id;
        }

        if(olmcProcessCode) {
            APIResult += "&olmcProcessCode=" + olmcProcessCode;
        }

        if(type) {
            APIResult += '&type=' + type;
        }

        if(olmcProcessId) {
            APIResult += "&olmcProcessId=" + olmcProcessId;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let processesResult = [];

            data.results.map(result => processesResult.push(
                new Process(result.id, 
                    result.creationDate, 
                    result.state, 
                    result.type,
                    result.lastUpdateDate, 
                    result.updatedBy,
                    result.olmcProcessCodeAndIds)
            ));
            
            return { results: processesResult, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetUsers() {
        let APIResult = this.UsersAPIEndpoint;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let users = [];

            data.map(result => users.push(
                new User(result.email, 
                    result.isActive,
                    )
            ));
            
            return { results: users };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetUserDetails(email) {
        const APIResult = this.UsersAPIEndpoint + "/" + email;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let user = new User(
                data.email,
                data.isActive,
                data.roles
            );

            return { results: user };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetRoles() {
        const APIResult = this.IdentityAPIEndpoint + "/roles";

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            let roles = data;

            return { results: roles };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    CreateUser(email, password, roles) {
        const APIResult = this.UsersAPIEndpoint;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }), body: JSON.stringify({ email: email, password: password, roles: roles })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    EditUser(email, password, roles) {
        const APIResult = `${this.UsersAPIEndpoint}/${email}`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'PUT', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }), body: JSON.stringify({ password: password, roles: roles })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    EditPassword(password) {
        const APIResult = this.UsersAPIEndpoint;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'PUT', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }), body: JSON.stringify({ password: password })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    MassiveReprocessPluginIntegrationResult(processKey, processState) {
        let APIResult = `${this.IntegrationResultsAPIEndpoint}/massivereprocess`;

        if(processKey !== "") {
            APIResult += '?processKey=' + processKey;
        }

        if(processState !== "") {
            APIResult += '&processState=' + processState;
        }

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    ReprocessPluginIntegrationResult(pluginResultId) {
        const APIResult = `${this.IntegrationResultsAPIEndpoint}/${pluginResultId}/reprocess`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    ExcludePluginIntegrationResult(pluginResultId) {
        const APIResult = `${this.IntegrationResultsAPIEndpoint}/${pluginResultId}/exclude`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    ReprocessOLMCMessage(olmcMessageId) {
        const APIResult = `${this.OLMCMessagesAPIEndpoint}/${olmcMessageId}/reprocess`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    ReprocessOLMCEvent(olmcEventId) {
        const APIResult = `${this.OLMCEventsAPIEndpoint}/${olmcEventId}/reprocess`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    RestartProcess(processId) {
        const APIResult = `${this.ProcessAPIEndpoint}/${processId}/restart`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            return { };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    //----//

    GetDistinctStates() {
        const APIResult = `${this.OLMCMessagesAPIEndpoint}/states`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return { states: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctMessageTypes() {
        const APIResult = `${this.OLMCMessagesAPIEndpoint}/types`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return { types: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctCodes() {
        const APIResult = `${this.OLMCMessagesAPIEndpoint}/processCodes`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            var filtered = data.filter(function (element) {
                return element != null;
              });
            return { codes: filtered };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctContractTypes() {
        const APIResult = `${this.GasContractsAPIEndpoint}/entrytypes`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return { types: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctProcessStates() {
        const APIResult = `${this.ProcessAPIEndpoint}/states`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return { states: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctProcessKeys() {
        const APIResult = `${this.IntegrationResultsAPIEndpoint}/processKeys`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return { keys: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctTypes() {
        const APIResult = `${this.ProcessAPIEndpoint}/types`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return { types: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetEnvironment() {
        const PingResult = this.EnvironmentPing;

        return fetch(PingResult, {method: 'GET', 'Content-Type': 'application/json'})
        .then(res => res.text())
        .then(data => {
            return { environment: data.toUpperCase() };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetOLMCEvents(currentPage, numberOfMessages, processId, olmcMessageId, state, type) {
        let APIResult = this.OLMCEventsAPIEndpoint + '?page=' + currentPage + '&pagesize=' + numberOfMessages;

        let token = JWTProvider.GetToken();

        if(processId) {
            APIResult += '&processId=' + processId;
        }

        if(olmcMessageId) {
            APIResult += '&olmcMessageId=' + olmcMessageId;
        }

        if(state) {
            APIResult += '&state=' + state;
        }

        if(type) {
            APIResult += '&type=' + type;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let olmcEvents = [];

            data.results.map(result => olmcEvents.push(
                new OLMCEvent(result.id,
                    result.eventType,
                    result.eventData,
                    result.state,
                    result.type,
                    result.creationDate,
                    result.processId,
                    result.olmcMessageId,)
            ));
            
            return { results: olmcEvents, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetPluginConfigurations(currentPage, numberOfMessages, key, value) {
        let APIResult = this.ConfigurationAPIEndpoint + '/plugins?page=' + currentPage + '&pagesize=' + numberOfMessages;

        let token = JWTProvider.GetToken();

        if(key) {
            APIResult += '&key=' + key;
        }

        if(value) {
            APIResult += '&value=' + value;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let pluginConfigurations = [];

            data.results.map(result => pluginConfigurations.push(
                new PluginConfigurations(result.pluginName,
                    result.key,
                    result.value)
            ));

            return { results: pluginConfigurations, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetStepConfigurations(currentPage, numberOfMessages, process, step, active, type) {
        let APIResult = this.ConfigurationAPIEndpoint + '/steps?page=' + currentPage + '&pagesize=' + numberOfMessages;

        let token = JWTProvider.GetToken();

        if(process) {
            APIResult += '&process=' + process;
        }

        if(step) {
            APIResult += '&step=' + step;
        }

        if(active) {
            APIResult += '&active=' + active;
        }

        if(type) {
            APIResult += '&type=' + type;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let stepConfigurations = [];

            data.results.map(result => stepConfigurations.push(
                new StepConfigurations(result.process,
                    result.step,
                    result.active,
                    result.creationDate,
                    result.type)
            ));

            return { results: stepConfigurations, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctStepProcesses() {
        const APIResult = `${this.ConfigurationAPIEndpoint}/stepprocesses`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            var filtered = data.filter(function (element) {
                return element != null;
              });
            return { processes: filtered };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetDistinctStepTypes() {
        const APIResult = `${this.ConfigurationAPIEndpoint}/steptypes`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            var filtered = data.filter(function (element) {
                return element != null;
              });
            return { types: filtered };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    ProcessesImport(processType, filename, filesize, binary) {
        const APIResult = `${this.ProcessesImportEndpoint}/importbyexcel`;

        let token = JWTProvider.GetToken();

        let username = JWTProvider.GetUsername();

        return fetch(APIResult, { method: 'POST',
                                  headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }),
                                  body: JSON.stringify({ processType: processType, filename: filename, filesize: filesize, binary: binary, username: username })
                                })
        .then(response => {
            if (!response.ok) {
                return response.json();
            } else {
                var data = { statusText: response.statusText, statusCode: response.status };
                return data;
            }
        })
        .then(function(parsedResponseOrData) {
            if (parsedResponseOrData.statusCode) { //se tiver este campo, quer dizer que vem do else acima e tenho de voltar a fazer o return da data
                return parsedResponseOrData;
            } else {
                let err = new Error(parsedResponseOrData.description);
                err.status = parsedResponseOrData.field;
                throw err;
            }
        })
        .catch(function(error) {
            var data = { statusText: error.message, statusCode: error.status };
            return data;
        });
    }

    GetProcessTypeEnum() {
        const APIResult = `${this.ProcessesImportEndpoint}/processtypeenum`;

        let token = JWTProvider.GetToken();

        return fetch(APIResult, {method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })})
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return { processTypes: data };
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetProcessesImport(currentPage, numberOfProcesses, filename, referenceId) {
        let APIResult = this.ProcessesImportEndpoint + '?page=' + currentPage + '&pagesize=' + numberOfProcesses;

        let token = JWTProvider.GetToken();

        if(filename) {
            APIResult += "&filename=" + filename;
        }

        if(referenceId) {
            APIResult += "&referenceId=" + referenceId;
        }
        
        return fetch(APIResult, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            
            return response.json();
        })
        .then(data => { 
            
            let processesImportResult = [];

            data.results.map(result => processesImportResult.push(
                new ProcessesImport(result.id, 
                    result.filename,
                    result.filesize,
                    result.binary,
                    result.processType,
                    result.creationDate, 
                    result.createdBy,
                    result.referenceId)
            ));
            
            return { results: processesImportResult, total: data.total }; 
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    GetReactConfig() {
        const EnvResult = this.ReactConfig;

        return EnvResult;
    }
}

export default integrationApi;