import GAListener from 'components/GAListener';
import { MainLayout } from 'components/Layout';

import React from 'react';
import componentQueries from 'react-component-queries';
import { Route, Switch, HashRouter } from 'react-router-dom';
import './styles/reduction.scss';
import integrationApi from 'apiservices/integrationApi';
import JWTProvider from 'apiservices/JWTProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Input, Label } from 'reactstrap';

const OLMCMessagesPage = React.lazy(() => import('pages/OLMCMessagesPage'));
const GasContractsPage = React.lazy(() => import('pages/GasContractsPage'));
const GasContractDetailsPage = React.lazy(() => import('pages/GasContractDetailsPage'));
const OLMCProcessesPage = React.lazy(() => import('pages/OLMCProcessesPage'));
const IntegrationResultsPage = React.lazy(() => import('pages/IntegrationResultsPage'));
const OLMCDashboardPage = React.lazy(() => import('pages/OLMCDashboardPage'));
const UsersPage = React.lazy(() => import('pages/UsersPage'));
const UserDetailsPage = React.lazy(() => import('pages/UserDetailsPage'));
const EditUserPage = React.lazy(() => import('pages/EditUserPage'));
const CreateUserPage = React.lazy(() => import('pages/CreateUserPage'));
const EditPasswordPage = React.lazy(() => import('pages/EditPasswordPage'));
const ProcessesPage = React.lazy(() => import('pages/ProcessesPage'));
const ProcessDetailsPage = React.lazy(() => import('pages/ProcessDetailsPage'));
const PluginConfigurationPage = React.lazy(() => import('pages/PluginConfigurationPage'));
const StepConfigurationPage = React.lazy(() => import('pages/StepConfigurationPage'));
const ProcessesImportPage = React.lazy(() => import('pages/ProcessesImportPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, errorStartingUp: false, invalidForm: false, logo: "" };
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    
    fetch('config/appsettings.json')
    .then(response => {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      
      return response.json();
    })
    .then(function(data) {
        integrationApi.BuildIntegrationAPI(data);
        this.setState({ loading: false });
    }.bind(this))
    .catch(function(error) {
      this.setState({ loading: false, errorStartingUp: true });
    }.bind(this));


    fetch('config/appsettings.json')
    .then(response => {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      
      return response.json();
    })
    .then(function(data) {
      this.setState({ loading: false, logo: data.REACT_CONFIG.LOGO });
    }.bind(this))
  }
  
  onLoginSubmit = () => {
    let username = document.getElementById("usernameAuthForm").value; 
    let password = document.getElementById("passwordAuthForm").value;

    this.setState({ loading: true });

    integrationApi.GetIntegrationAPI().GetToken(username, password).then(data => {
      if(data) {
        JWTProvider.SetToken(data.result.token, data.result.expirationDateUTC);
      }
      
      this.setState({ loading: false, invalidForm: true });
      
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.onLoginSubmit();
    }
  }

  render() {
    var logo = this.state.logo;
    
    if(!this.state.loading && !this.state.errorStartingUp){

      if (!JWTProvider.HasToken()) {
        return (
          <div className="row" style={{ marginTop: "15%", justifyContent: "center", alignItems: "center" }}>
            <div className="col-md-6 col-lg-3">
              <div className="card card-body">
                <form>
                  <div className="text-center pb-4">
                  {
                    <img src={logo} width="190" height="49.6" alt="" style={{marginTop: 4, borderRadius: "20"}} />
                  }
                  </div>
                  <div className="form-group">
                    <label htmlFor="Email" className="">Email</label>
                    <Input placeholder="" id="usernameAuthForm" type="email" className="form-control" invalid={this.state.invalidForm} onKeyPress={this.handleKeyPress} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Password" className="">Password</label>
                    <Input placeholder="" id="passwordAuthForm" type="password" className="form-control" invalid={this.state.invalidForm} onKeyPress={this.handleKeyPress} />
                  </div>
                  {
                    this.state.invalidForm === true ?
                    <Label style={{ color: "red", display: "block", textAlign: "center" }}>Email ou Password estão incorrectos</Label> :
                    <span />
                  }
                  <hr />
                  <button onClick={this.onLoginSubmit} type="button" className="bg-gradient-theme-left border-0 btn btn-secondary btn-lg btn-block">Login</button>
                </form>
              </div>
            </div>
          </div>);
      }
      
      return <HashRouter basename={getBasename()}>
         <GAListener>
           <Switch>
              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<span></span>}>
                  <Route exact path="/" component={props => <OLMCDashboardPage {...props} />} />
                  <Route exact path="/olmc-messages" component={props => <OLMCMessagesPage {...props} />} />
                  <Route exact path="/gas-contracts" component={props => <GasContractsPage {...props} />} />
                  <Route path="/gas-contracts/:id" component={props => <GasContractDetailsPage {...props} />} />
                  <Route exact path="/olmc-processes" component={props => <OLMCProcessesPage {...props} />} />
                  <Route exact path="/processes" component={props => <ProcessesPage {...props} />} />
                  <Route path="/olmc-messages/:olmcProcessId" component={props => <OLMCMessagesPage {...props} />} />
                  <Route exact path="/integration-results" component={props => <IntegrationResultsPage {...props} />} />
                  <Route path="/integration-results/:integrationResult" component={props => <IntegrationResultsPage {...props} />} />
                  <Route exact path="/user" component={props => <UsersPage {...props} />} />
                  <Route path="/user/details/:email" component={props => <UserDetailsPage {...props} />} />
                  <Route path="/user/edit/:email" component={props => <EditUserPage {...props} />} />
                  <Route exact path="/user/create" component={props => <CreateUserPage {...props} />} />
                  <Route exact path="/edit-password" component={props => <EditPasswordPage {...props} />} />
                  <Route path="/process-details/:id" component={props => <ProcessDetailsPage {...props} />} />
                  <Route exact path="/plugin-configurations" component={props => <PluginConfigurationPage {...props} />} />
                  <Route exact path="/step-configurations" component={props => <StepConfigurationPage {...props} />} />
                  <Route exact path="/processes-import" component={props => <ProcessesImportPage {...props} />} />
                </React.Suspense>
              </MainLayout>
            </Switch>
          </GAListener>
        </HashRouter>;
    } else if(!this.state.loading && this.state.errorStartingUp){
      return <p style={{margin: '20px 20px'}}>The application was unable to start. Please contact the system administrator.</p>;
    }
    else {
      return (
        <div style={{backgroundColor: 'rgba(0, 0, 200, 0.10)', padding: '24px', position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', zIndex: '100'}}>
            <div style={{margin: '20% auto', width: '100px'}}>
                <CircularProgress size={100} />
            </div>
        </div>
      );
    }
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
