class Process {
    constructor(id, 
        creationDate, 
        state, 
        type,
        lastUpdateDate, 
        updatedBy,
        OLMCProcessCodeAndIds) {
            this.id = id;
            this.creationDate = creationDate;
            this.state = state;
            this.lastUpdateDate = lastUpdateDate;
            this.updatedBy = updatedBy;
            this.type = type;
            this.OLMCProcessCodeAndIds = OLMCProcessCodeAndIds;
    }
}

export default Process;