class ProcessesImport {
    constructor(id, 
        filename, 
        filesize, 
        binary,
        processType, 
        creationDate,
        createdBy,
        referenceId) {
            this.id = id;
            this.filename = filename;
            this.filesize = filesize;
            this.binary = binary;
            this.processType = processType;
            this.creationDate = creationDate;
            this.createdBy = createdBy;
            this.referenceId = referenceId;
    }
}

export default ProcessesImport;